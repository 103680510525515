import * as React from "react"

import { cva, type VariantProps } from "./cva.ts"

export const Link = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<"a"> & VariantProps<typeof classForLink>
>(({ className, ...props }, ref) => {
  return <a className={classForLink({ className })} ref={ref} {...props} />
})

Link.displayName = "Link"

export const classForLink = cva([
  "underline underline-offset-4",
  "text-gray-11",
  "hover:text-gray-12",
  "focus-visible:text-gray-12",
  "active:text-gray-11",
])

export const LinkButton = React.forwardRef<
  HTMLButtonElement,
  VariantProps<typeof classForLinkButton> & React.ComponentPropsWithoutRef<"button">
>(({ className, disabled, ...props }, ref) => {
  return (
    <button
      className={classForLinkButton({ className, disabled })}
      disabled={disabled}
      ref={ref}
      {...props}
    />
  )
})

LinkButton.displayName = "LinkButton"

export const classForLinkButton = cva(
  [
    "inline-flex gap-2 items-center justify-center flex-grow-0",
    "text-sm/4",
    "text-gray-11",
    "hover:text-gray-12",
    "focus-visible:text-gray-12",
    "active:text-gray-11",
    "underline underline-offset-4",
  ],
  {
    variants: {
      disabled: {
        true: "text-gray-8 pointer-events-none",
        false: "",
      },
      size: {
        sm: "py-1",
        md: "py-2",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
)
